import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InsuranceComponent } from './pages/insurance/insurance.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';


const routes: Routes = [
  { path: '', component: InsuranceComponent },
  { path: 'payment/:id', component: PaymentComponent },
  { path: 'payment/confirmation/:id', component: ConfirmationComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
