import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass']
})
export class PaymentComponent implements OnInit {
  @ViewChild('paymentForm',{ static: true}) public any: NgForm;
  insure: any; otp: any; verify: boolean;
  constructor(private api: ApiService, private router: Router, private rout: ActivatedRoute, private notify: NotificationService) { }

  ngOnInit() {
    this.init();
    if(this.rout.snapshot.paramMap.has('id')) {
      console.log(this.rout.snapshot.paramMap.get('id'));
      this.getInsurance(this.rout.snapshot.paramMap.get('id'));
    }
  }

  getInsurance(val) {
    this.api.get('insurance/' + val).subscribe((data:any)=>{
      this.insure = data;
    })
  }

  postPayment() {
    this.api.post('payment', this.insure).subscribe(data=>{
      this.insure = data.insure; this.api.data = data.insure;
      // setTimeout(() => { this.getInsurance(this.insure._id); }, 100001);
      this.api.show();
      window.location.href = "payment/confirmation/" + this.insure._id;
      // this.router.navigate(['confirmation', data._id]);
    })
  }

  Verify() {
    this.api.gets('verify/'+this.insure.payment.mobile+'/'+ this.insure.payment.token).subscribe((data:any)=>{
      this.verify = data.status
      this.notify.success({output: 'Verification Code', message: data.message})
    });
  }

  sendOtp() {
    this.insure.payment.token = "";
    this.api.gets(this.insure.payment.mobile+'/insurance').subscribe((data:any)=>{
      this.otp = data.otps.otp || data.otps.send_otp;
      this.notify.success({output: 'Verification Code', message: data.message})
    });
  }

  init() {
    this.insure = {
      firstname: "", lastname: "", othername: "", dateofbirth: "", mobile: "", email: "", occupation: "", address: "", id_type: "", id_number: "", tin: "", packageid: "", status: "Pending", details: { cust_type: "", cover_type: "Taxi Commercial", vehicle: "", model: "", color: "", no_of_seats: "", year_of_manufacture: "", chasis_number: "", reg_number: "", body_type: "" }, payment: { amount: 0, mobile: 0, network: '', token: '' }
    }
  }
}
