import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.sass']
})
export class ConfirmationComponent implements OnInit {
  insure: any; otp: any;
  constructor(private api: ApiService, private router: Router, private rout: ActivatedRoute, private notify: NotificationService) { }

  ngOnInit() {
    if(this.rout.snapshot.paramMap.has('id')) {
      this.getInsurance(this.rout.snapshot.paramMap.get('id'));
    }
  }

  getInsurance(val) {
    this.api.get('insurance/' + val).subscribe((data:any)=>{
      this.insure = data;
      if(data.callback) {
        if(data.callback.status_code == 1) {
          this.notify.success({ output: "Paid", message: "Payment is completed successfully"});
          window.location.href = "/";
        } else if(data.callback.status_code == 0) {
          setTimeout(() => { this.getInsurance(this.insure._id); }, 100000);
        } else {
          this.notify.error({ output: "Fail", message: "Payment not successful, try buying "});
          window.location.href = "payment/" + data._id;
        }
      }
    })
  }

}
