import { Injectable } from '@angular/core';
// import { AngularFirestore } from '@angular/fire/firestore';
// import { AngularFireMessaging } from '@angular/fire/messaging';
// import { AngularFireFunctions } from '@angular/fire/functions';
import Swal from 'sweetalert2';

// Import firebase to fix temporary bug in AngularFire
// import * as app from 'firebase';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  token: any; user: any; isLoading: boolean;
  constructor(private api: ApiService) {
    // this.user = this.use.user();
  }

  notify(message) {
    // this.toastr.error(message, 'Error Message');
  }

  async makeToast(data) {
    // const toast = await this.toastr.info(data.message, data.title);
    // {
    //   message,
    //   duration: 5000,
    //   position: 'top',
    //   showCloseButton: true,
    //   closeButtonText: 'dismiss'
    // });
    // toast.onShown();
  }

  error(err) {
    Swal.fire({
      position: "top-end",
      icon: "error",
      text: err || 'Internal Server Error',
      backdrop: false, background: 'transprent',
      showConfirmButton: true,
      // timer: 3000
    })
  }

  success(data) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: data.output || 'success',
      text: data.message || 'Your work has been saved',
      showConfirmButton: true,
      backdrop: false,
      timer: 1500
    })
  }

  async Notify(data) {
    // console.log(data);
    if (data.message != null && data.message.length > 0) {
      const Toast = Swal.mixin({
        // position: 'top-end',
        // toast: true,
        backdrop: false,
        showConfirmButton: true,
        // timer: 5000
      });

      Toast.fire({
        icon: 'success',
        title: data.output || 'Successful',
        text: data.message,
      });
    }
  }

}
