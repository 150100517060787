import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.sass']
})
export class InsuranceComponent implements OnInit {
  @ViewChild('insuranceForm',{ static: true}) public insuranceForm: NgForm;
  packages: any[]; insure: any; package: any = { fixed: true, percentage: 0 };
  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
    this.init();
    this.getPackages();
  }

  getPackages() {
    this.api.get('packages').subscribe((data:any[])=>{
      this.packages = data;
    })
  }

  getPackage(val) {
    this.api.get('package/' + val).subscribe((data:any)=>{
      this.package = data;
      this.insure.payment.amount = data.amount;
      this.insure.details.cover_type = data.name;
      this.insure.payment.type = data.name;
      this.calculate();
    })
  }

  postInsurance() {
    this.api.post('insurance', this.insure).subscribe(data=>{
      this.insure = data; this.api.data = data;
      this.api.show();
      window.location.href = "payment/" + data._id;
      // this.router.navigate(['payment', data._id]);
    })
  }

  calculate() {
    if(this.insure.details.cover == 'FIRE & THEFT' && this.insure.details.worth > 0) {
      this.insure.payment.amount = this.package.amount + (this.insure.details.worth * (this.package.fire_theft_rate)) / 100;
      if(this.insure.details.no_of_seats > this.package.no_of_seat) {
        this.insure.payment.amount += this.package.extra_seat_charge * (this.insure.details.no_of_seats - this.package.no_of_seat);
      }
    } else if (this.insure.details.cover == 'COMPREHENSIVE' && this.insure.details.worth > 0) {
      this.insure.payment.amount = this.package.amount + (this.insure.details.worth * this.package.percentage) / 100;
      if(this.insure.details.no_of_seats > this.package.no_of_seat) {
        this.insure.payment.amount += this.package.extra_seat_charge * (this.insure.details.no_of_seats - this.package.no_of_seat);
      }
    } else {
      this.insure.payment.amount = this.package.amount;
      if(this.insure.details.no_of_seats > this.package.no_of_seat) {
        this.insure.payment.amount += this.package.extra_seat_charge * (this.insure.details.no_of_seats - this.package.no_of_seat);
      }
    }
  }

  init() {
    this.insure = {
      fullname: "", firstname: "", lastname: "", othername: "", dateofbirth: new Date(), mobile: "", email: "", occupation: "", address: "", id_type: "", id_number: "", tin: "", package_id: "", details: { cust_type: "", cover: "THIRD PARTY BASIC TARRIF", cover_type: "", vehicle: "", model: "", color: "", no_of_seats: 0, year_of_manufacture: "", chasis_number: "", reg_number: "", body_type: "", worth: 0 }, payment: { amount: 0, mobile: 0 }
    }
  }
}
